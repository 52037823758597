import { Dialog, Transition } from '@headlessui/react';
import { useGetUserQuery } from 'components/profile/userSlice';
import { format } from 'date-fns';
import { Fragment } from 'react';
import AnnouncementAction from './AnnouncementAction';

// import StatusHistory from './StatusHistory';
// import { useUpdateApplicationMutation } from 'features/home/applicationSlice';

const AnnouncementPopup = ({
  data,
  isOpen,
  setIsOpen,
}: {
  data: any;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  //   const [update] = useUpdateApplicationMutation();

  //   useEffect(() => {
  //     if (notificationStatus) {
  //       update({ applicationId })
  //         .unwrap()
  //         .then((res) => {});
  //     }
  //   }, [notificationStatus, applicationId, update]);

  const { data: userData } = useGetUserQuery();

  function isActionDone() {
    return data?.studentActions?.some(
      (student: any) => student.userId === userData._id
    );
  }

  const showActionRequired = data?.actionRequired && !isActionDone();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <div className='mt-4'>
                  <section aria-labelledby='announcements-title'>
                    <div className='overflow-hidden rounded-lg bg-white shadow'>
                      <div className='p-6'>
                        <h2
                          className='text-base font-medium text-gray-900'
                          id='announcements-title'
                        >
                          Announcement
                        </h2>
                        {data?.createdAt && (
                          <p className='text-gray-500 text-xs'>
                            Date:{' '}
                            {format(new Date(data?.createdAt), 'do MMM yyy')}
                          </p>
                        )}

                        <div className='mt-6 flow-root'>
                          <ul>
                            <li key={data?._id} className='py-5'>
                              <div className='relative focus-within:ring-2 focus-within:ring-cyan-500'>
                                <h3 className='text-sm font-semibold text-gray-800'>
                                  <a
                                    href={data?.href}
                                    className='hover:underline focus:outline-none'
                                  >
                                    {/* Extend touch target to entire panel */}
                                    <span
                                      className='absolute inset-0'
                                      aria-hidden='true'
                                    />
                                    {data?.title}
                                  </a>
                                </h3>
                                <p className='mt-1 text-sm text-gray-600 line-clamp-2'>
                                  {data?.message}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                        {showActionRequired && (
                          <AnnouncementAction
                            data={data}
                            setIsOpen={setIsOpen}
                          />
                        )}
                      </div>
                    </div>
                  </section>
                </div>
                <div className='mt-4'>
                  <button
                    type='button'
                    className='ml-6 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50  sm:text-sm'
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AnnouncementPopup;
