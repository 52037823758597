import DashboardLayout from 'layout/DashboardLayout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from 'features/home';
import Profile from 'features/profile';
import PrivateRoute from './PrivateRoute';
import Login from 'components/auth/Login';
import AMPLIFY_CONFIG from 'config/AmplifyConfig';
import { Amplify } from 'aws-amplify';
import CheckLink from 'components/auth/CheckLink';
import SignUp from 'components/auth/SignUp';
import Roles from 'features/roles';
import ViewRole from 'components/roles/ViewRole';

const Index = () => {
  Amplify.configure(AMPLIFY_CONFIG);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/signup'>
          <Route index element={<CheckLink />} />
          <Route path=':slug' element={<SignUp />} />
        </Route>
        <Route path='login' element={<Login />} />
        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<DashboardLayout />}>
            <Route index element={<Home />} />
            <Route path='profile' element={<Profile />} />
            <Route path='roles'>
              <Route index element={<Roles />} />
              <Route path=':roleId' element={<ViewRole />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Index;
