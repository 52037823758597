import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { useGetCompanyQuery } from 'features/roles/rolesSlice';

const CompanyDetails = ({
  roleTitle,
  roleId,
  companyId,
  companyName,
}: {
  roleTitle: string;
  roleId: string;
  companyId: string;
  companyName: string;
}) => {
  const { data, isSuccess } = useGetCompanyQuery({
    placementCompanyId: companyId,
  });
  return (
    <>
      {isSuccess && data.length > 0 && (
        <div>
          <div className='flex gap-8 justify-between'>
            <div className='flex gap-8 justify-between'>
              <div className='flex gap-4'>
                <img
                  src={UPLOAD_OR_DOWNLOAD_URL + '/' + data[0].companyLogo}
                  alt='Company'
                  className='h-16'
                />
                <div>
                  <p className='text-lg font-medium'>{companyName}</p>
                  <p className='text-sm text-gray-500'>
                    {data[0].industryId.title}
                  </p>
                </div>
              </div>
            </div>
            <div className='flex gap-4 items-center'>
              {data[0].socialMediaHandles?.linkedIn && (
                <a
                  href={data[0].socialMediaHandles?.linkedIn}
                  rel='noreferrer noopener'
                  target='_blank'
                  className='text-sm text-blue-400'
                >
                  <svg
                    width='19'
                    height='19'
                    viewBox='0 0 19 19'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='fill-primary-light hover:fill-primary'
                  >
                    <path d='M16.2083 0.875C16.7167 0.875 17.2042 1.07693 17.5636 1.43638C17.9231 1.79582 18.125 2.28333 18.125 2.79167V16.2083C18.125 16.7167 17.9231 17.2042 17.5636 17.5636C17.2042 17.9231 16.7167 18.125 16.2083 18.125H2.79167C2.28333 18.125 1.79582 17.9231 1.43638 17.5636C1.07693 17.2042 0.875 16.7167 0.875 16.2083V2.79167C0.875 2.28333 1.07693 1.79582 1.43638 1.43638C1.79582 1.07693 2.28333 0.875 2.79167 0.875H16.2083ZM15.7292 15.7292V10.65C15.7292 9.82142 15.4 9.02678 14.8141 8.44088C14.2282 7.85499 13.4336 7.52583 12.605 7.52583C11.7904 7.52583 10.8417 8.02417 10.3817 8.77167V7.70792H7.70792V15.7292H10.3817V11.0046C10.3817 10.2667 10.9758 9.66292 11.7137 9.66292C12.0696 9.66292 12.4108 9.80427 12.6625 10.0559C12.9141 10.3075 13.0554 10.6488 13.0554 11.0046V15.7292H15.7292ZM4.59333 6.20333C5.02033 6.20333 5.42984 6.03371 5.73178 5.73178C6.03371 5.42984 6.20333 5.02033 6.20333 4.59333C6.20333 3.70208 5.48458 2.97375 4.59333 2.97375C4.16379 2.97375 3.75185 3.14438 3.44811 3.44811C3.14438 3.75185 2.97375 4.16379 2.97375 4.59333C2.97375 5.48458 3.70208 6.20333 4.59333 6.20333ZM5.92542 15.7292V7.70792H3.27083V15.7292H5.92542Z' />
                  </svg>
                </a>
              )}
              <a
                href={data[0].website}
                rel='noreferrer noopener'
                target='_blank'
                className='text-sm text-blue-400'
              >
                <button
                  type='button'
                  className='inline-flex items-center justify-center rounded-md   px-4 py-2 text-sm font-medium text-primary  hover:bg-primary-lighter focus:bg-primary-light focus:text-white focus:outline-none sm:w-auto'
                >
                  Website
                  <ArrowUpOnSquareIcon
                    className='-mr-0.5 h-5 w-5'
                    aria-hidden='true'
                  />
                </button>
              </a>
            </div>
          </div>
          {data[0].companyDescription && (
            <div>
              <h3 className='font-medium mb-2'>About the company</h3>
              <p className='mb-4 text-sm text-gray-600'>
                {data[0].companyDescription}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CompanyDetails;
