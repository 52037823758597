import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/placement-announcement/user',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Announcement'],
    }),
    addAction: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/placement-announcement/add-action',
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to the `Partner`.
      invalidatesTags: ['Announcement'],
    }),
  }),
});

export const { useGetAnnouncementsQuery, useAddActionMutation } =
  extendedApiSlice;
