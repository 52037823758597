import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { UserIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { format } from 'date-fns';

export default function StatusHistory({
  statusHistory,
}: {
  statusHistory: any;
}) {
  return (
    <div className='w-full'>
      <div className='mx-auto w-full max-w-md rounded-2xl bg-white'>
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className='flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75'>
                <span>Status History</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className='px-4 pt-4 pb-2 text-sm text-gray-500'>
                <div className='flow-root'>
                  <ul className='-mb-8'>
                    {statusHistory.map((history: any, eventIdx: number) => (
                      <li key={history._id}>
                        <div className='relative pb-8'>
                          {eventIdx !== statusHistory.length - 1 ? (
                            <span
                              className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
                              aria-hidden='true'
                            />
                          ) : null}
                          <div className='relative flex space-x-3'>
                            <div>
                              <span
                                className={classNames(
                                  'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                                  {
                                    'bg-blue-500': history.status === 'Applied',
                                  },
                                  {
                                    'bg-green-500': history.status === 'Hired',
                                  },
                                  {
                                    'bg-red-500':
                                      history.status === 'Not Hired',
                                  },
                                  {
                                    'bg-yellow-500':
                                      history.status === 'Shortlisted',
                                  }
                                )}
                              >
                                <UserIcon
                                  className='h-5 w-5 text-white'
                                  aria-hidden='true'
                                />
                              </span>
                            </div>
                            <div className='flex min-w-0 flex-1 justify-between space-x-4 pt-1.5'>
                              <div>
                                <p className='text-sm text-gray-500'>
                                  <span className='font-medium text-gray-900'>
                                    {history.status}
                                  </span>{' '}
                                  by {history.updatedBy.name}
                                </p>
                                <p>{history.notes}</p>
                              </div>
                              <div className='whitespace-nowrap text-right text-sm text-gray-500'>
                                <time>
                                  {format(
                                    new Date(history.updatedOn),
                                    'do LLLL yyyy'
                                  )}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
