import AllRoles from 'components/roles/AllRoles';
import NoRoles from 'components/roles/NoRoles';
import AllApplications from 'components/applications.tsx/AllApplications';
import Error from 'layout/Error';
import Loading from 'layout/Loading';
import { useGetRolesQuery } from './rolesSlice';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import Header from 'components/roles/Header';
import Tabs from 'components/roles/Tabs';
import { useMemo, useState } from 'react';
import { useGetApplicationsQuery } from 'features/home/applicationSlice';

const Index = () => {
  const [view, setView] = useState('Roles');
  const {
    data = [],
    isSuccess,
    isLoading,
    isError,
  } = useGetRolesQuery({ limit: 0 });

  const { data: applications, isSuccess: applicationsSuccess } =
    useGetApplicationsQuery({
      limit: 0,
      onlyUser: true,
    });

  const rolesWithoutApplications = useMemo(() => {
    return data.filter((role: any) => {
      return !applications?.some((application: any) => {
        return application.placementRoleId._id === role._id;
      });
    });
  }, [applications, data]);

  const pages = [{ name: 'Roles', href: '/roles', current: true }];

  return (
    <>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <Header view={view} />
      {isLoading && <Loading />}
      {isError && <Error />}
      <Tabs view={view} setView={setView} />
      {view === 'Roles' ? (
        isSuccess &&
        applicationsSuccess &&
        rolesWithoutApplications.length > 0 ? (
          <AllRoles data={rolesWithoutApplications} />
        ) : (
          <NoRoles />
        )
      ) : (
        <AllApplications />
      )}
    </>
  );
};

export default Index;
