import { buttonStyle } from 'features/profile/helper';
import { Field, Form, Formik } from 'formik';
import { useAddActionMutation } from './AnnouncementSlice';

const AnnouncementAction = ({
  data,
  setIsOpen,
}: {
  data: any;
  setIsOpen: any;
}) => {
  const [update] = useAddActionMutation();
  return (
    <>
      {data?.actionRequired && (
        <div>
          <Formik
            initialValues={{
              action: '',
              announcementId: data._id,
            }}
            onSubmit={(values, { setSubmitting }) => {
              update(values)
                .unwrap()
                .then(() => {
                  setSubmitting(false);
                  setIsOpen(false);
                });
            }}
          >
            <Form>
              <label htmlFor='action'>Add link as proof of action</label>
              <Field
                id='action'
                name='action'
                placeholder='Add link as proof'
                className='mt-4 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 mb-4'
              />

              <button type='submit' className={buttonStyle}>
                Submit
              </button>
            </Form>
          </Formik>
        </div>
      )}
    </>
  );
};

export default AnnouncementAction;
