import { useGetCompanyQuery } from 'features/roles/rolesSlice';
import Application from './Application';

const RoleHeader = ({
  roleTitle,
  companyId,
  companyName,
  roleId,
}: {
  roleTitle: string;
  companyId: string;
  companyName: string;
  roleId: string;
}) => {
  const { data, isSuccess } = useGetCompanyQuery({
    placementCompanyId: companyId,
  });
  return (
    <>
      {isSuccess && (
        <div>
          <div className='flex gap-8 justify-between mb-4 pb-2 border-b'>
            <div>
              <p className='text-lg font-semibold'>{roleTitle}</p>
              <p className='text-sm'>
                Job opening at{' '}
                <a
                  href={data[0].website}
                  target='_blank'
                  rel='noreferrer noopener'
                  className='text-blue-400'
                >
                  {companyName}
                </a>
              </p>
            </div>
            <Application roleId={roleId} />
          </div>
        </div>
      )}
    </>
  );
};

export default RoleHeader;
