import { useGetApplicationsQuery } from 'features/home/applicationSlice';
import Loading from 'layout/Loading';
import ApplicationTable from './ApplicationTable';

const ApplicationDetails = () => {
  const { data, isLoading, isSuccess } = useGetApplicationsQuery({
    limit: 0,
    onlyUser: true,
  });

  return (
    <div>
      {isLoading && <Loading />}
      {isSuccess && data.length > 0 ? (
        <ApplicationTable data={data} />
      ) : (
        <div>{/* if user has 0 Application */}</div>
      )}
    </div>
  );
};

export default ApplicationDetails;
