import Loading from 'layout/Loading';
import React from 'react';
import { useGetUserQuery } from '../userSlice';
import Stats from './Stats';
import StudentDescription from './StudentDescription';
import StudentEducation from './StudentEducation';
import StudentExperience from './StudentExperience';
import { Element } from 'react-scroll';
// import { PorfileNavigation } from 'features/profile/types';

const Index = ({
  // item,
  handleSwitch,
  setShowFullProfile,
}: {
  // item: PorfileNavigation;
  handleSwitch: (value: number) => void;
  setShowFullProfile: any;
}) => {
  const { isLoading, isSuccess, data } = useGetUserQuery();

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <div className='-mx-8 text-neutral-900'>
          <StudentDescription
            // item={item}
            handleSwitch={handleSwitch}
            data={data}
            setShowFullProfile={setShowFullProfile}
          />
          <Element name='education-section'>
            {data?.education?.length ? <StudentEducation data={data} /> : null}
          </Element>
          <Element name='experience-section'>
            {data?.workExperience?.length ? (
              <StudentExperience data={data} />
            ) : null}
          </Element>
          <Stats data={data} />
        </div>
      )}
    </>
  );
};

export default Index;
