import classNames from 'classnames';
import { useState } from 'react';
import StatusPopup from './StatusPopup';
import { format } from 'date-fns';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/24/outline';

const ApplicationTable = ({ data }: { data: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [applicationToEdit, setApplicationToEdit] = useState('');
  const [notificationStatus, setNotificationStatus] = useState(false);
  const [statusHistory, setStatusHistory] = useState([]);

  const totalNotification = data.filter((app: any) => {
    return app.showNotification;
  }).length;

  return (
    <>
      <h3 className='mt-2 text-lg font-semibold tracking-tight text-gray-900'>
        Applications
      </h3>
      {totalNotification > 0 && (
        <h6 className='mt-2 font-semibold'>
          {`You have ${totalNotification} new ${
            totalNotification === 1 ? 'notification' : 'notifications'
          }`}
        </h6>
      )}
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                    >
                      Role Name
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Company
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Status
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Stage
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                    >
                      Applied On
                    </th>
                    <th
                      scope='col'
                      className='relative py-3.5 pl-3 pr-4 sm:pr-6'
                    >
                      <span className='sr-only'>View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {data.map((application: any) => (
                    <tr key={application._id}>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                        <div className='flex items-center'>
                          <div className='ml-4'>
                            <div className='font-medium text-gray-900'>
                              {application.placementRoleId?.title}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {application.placementRoleId?.placementCompanyId?.name}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm'>
                        <span
                          className={classNames(
                            'inline-flex rounded-full  px-2 py-1 text-xs font-semibold leading-5 ',
                            {
                              'bg-green-100 text-green-800':
                                application.currentStatus === 'Hired',
                            },
                            {
                              'bg-blue-100 text-blue-800':
                                application.currentStatus === 'Applied',
                            },
                            {
                              'bg-yellow-100 text-yellow-800':
                                application.currentStatus === 'Shortlisted',
                            },
                            {
                              'bg-red-100 text-red-800':
                                application.currentStatus === 'Not Hired',
                            }
                          )}
                        >
                          {application.currentStatus}
                        </span>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {application.currentStage}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {format(
                          new Date(application.appliedOn),
                          'do LLLL yyyy'
                        )}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {application.showNotification && <BellAlertIcon />}
                      </td>
                      <td className='relative whitespace-nowrap  pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                        <Link to={`/roles/${application.placementRoleId._id}`}>
                          <EyeIcon className='w-6' />
                        </Link>
                        <button
                          className='text-indigo-600 hover:text-indigo-900 flex'
                          onClick={() => {
                            setApplicationToEdit(application._id);
                            setIsOpen(true);
                            setNotificationStatus(application.showNotification);
                            setStatusHistory(application.statusHistory);
                          }}
                        >
                          status
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <StatusPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        notificationStatus={notificationStatus}
        applicationId={applicationToEdit}
        statusHistory={statusHistory}
      />
    </>
  );
};

export default ApplicationTable;
