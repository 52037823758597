import { EyeIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const AllRoles = ({ data }: { data: any }) => {
  return (
    <div className='flex flex-col'>
      <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8'>
        <div className='inline-block  min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='shadow ring-1 ring-black ring-opacity-5'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                  >
                    Role Title
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Company
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Role Type
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Experience Level
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Qualification
                  </th>
                  <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                    <span className='sr-only'>Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {data.map((role: any) => (
                  <tr key={role._id}>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                      <div className='flex items-center'>
                        <div className='font-medium text-gray-900'>
                          {role.title}
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {role.placementCompanyId?.name}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {role.roleType}
                      <br />
                      {role.workCulture}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {role.experienceLevel}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {role.educationQualification}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex gap-4'>
                      <Link to={`/roles/${role._id}`}>
                        <EyeIcon className='w-6' />
                      </Link>
                    </td>
                    {/* <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                    <Options
                      options={[
                        {
                          text: 'View',
                          link: `/cycle/view/${role.placementCycleId._id}/roles/${role._id}`,
                        },
                        {
                          text: 'Edit',
                          link: `/cycle/view/${role.placementCycleId._id}/roles/edit/${role._id}`,
                        },
                      ]}
                    />
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRoles;
