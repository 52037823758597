import React from 'react';
import { profilePercentage } from 'components/helper/helperFun';
import { Link } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/20/solid';

const Index = ({ data }: { data: any }) => {
  return (
    <div className='text-center'>
      <div className='flex justify-center mb-2'>
        <UserIcon className='text-primary h-10' />
      </div>

      <h1>
        Welcome {data?.firstName} {data?.lastName}{' '}
      </h1>
      <div className='my-4 font-bold'>
        Your Profile Status : {profilePercentage(data)} %
      </div>
      <Link className='text-primary' to='/profile'>
        View Profile
      </Link>
      <div className='bg-gray-200 h-6 mt-4 mx-2'>
        <div
          className='bg-primary h-6 text-xs font-medium text-blue-100 text-center  leading-none rounded flex items-center justify-center'
          style={{ width: `${profilePercentage(data)}%` }}
        >
          {profilePercentage(data)}%
        </div>
      </div>
    </div>
  );
};

export default Index;
