import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { buttonStyle } from 'components/helper/helper';
import { useApplyApplicationMutation } from 'features/roles/rolesSlice';

const Apply = ({ roleId }: { roleId: string }) => {
  const [apply] = useApplyApplicationMutation();
  return (
    <div>
      <button
        type='button'
        className={buttonStyle}
        onClick={() => {
          if (window.confirm('Are you sure you want to apply to this role?')) {
            apply({ placementRoleId: roleId });
          }
        }}
      >
        <EnvelopeIcon className='-ml-0.5 mr-2  h-5 w-5 ' />
        <span className='shrink-0'> Apply Now</span>
      </button>
    </div>
  );
};

export default Apply;
