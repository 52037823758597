import {
  BanknotesIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  ClipboardIcon,
} from '@heroicons/react/24/outline';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import CompanyDetails from './CompanyDetails';
import RoleHeader from './RoleHeader';

const RoleDetail = ({ data }: { data: any }) => {
  const pages = [
    { name: 'Roles', href: '/roles', current: false },
    {
      name: data[0].title,
      href: '/roles/' + data[0]._id,
      current: true,
    },
  ];

  return (
    <div>
      <nav className='flex mb-4' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          <li>
            <div>
              <Link to='/' className='text-gray-400 hover:text-gray-500'>
                <HomeIcon
                  className='h-5 w-5 flex-shrink-0'
                  aria-hidden='true'
                />
                <span className='sr-only'>Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='h-5 w-5 flex-shrink-0 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  to={page.href}
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div className='space-y-4 p-4 border rounded max-w-3xl mt-8'>
        <RoleHeader
          roleTitle={data[0].title}
          companyId={data[0].placementCompanyId._id}
          companyName={data[0].placementCompanyId.name}
          roleId={data[0]._id}
        />
        <div className='space-y-2 border-b pb-2'>
          <div className='flex gap-4'>
            <BriefcaseIcon className='h-5 w-5 text-gray-500' />
            <p>{data[0].roleType}</p>
          </div>
          <div className='flex gap-4'>
            <BuildingOfficeIcon className='h-5 w-5 text-gray-500' />
            <p>{data[0].workCulture}</p>
          </div>
          <div className='flex gap-4'>
            <ClipboardIcon className='h-5 w-5 text-gray-500' />
            <p>{data[0].domain}</p>
          </div>
          {data[0].disclosedSalary && (
            <div className='flex gap-4'>
              <BanknotesIcon className='h-5 w-5 text-gray-500' />
              {data[0].disclosedSalary && data[0].payRange} LPA
            </div>
          )}
        </div>
        <div className='pb-2 border-b'>
          <h3 className='font-medium mb-2'>Job Description</h3>
          <p className='mb-4 text-sm text-gray-600'>
            {data[0].roleDescription}
          </p>
        </div>
        <CompanyDetails
          roleTitle={data[0].title}
          roleId={data[0]._id}
          companyId={data[0].placementCompanyId._id}
          companyName={data[0].placementCompanyId.name}
        />
      </div>
    </div>
  );
};

export default RoleDetail;
