import ApplicationTable from 'components/home/ApplicationTable';
import { useGetApplicationsQuery } from 'features/home/applicationSlice';
import Error from 'layout/Error';
import Loading from 'layout/Loading';

const AllApplications = () => {
  const {
    data = [],
    isSuccess,
    isLoading,
    isError,
  } = useGetApplicationsQuery({ limit: 0, onlyUser: true });

  return (
    <>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess && data.length > 0 ? <ApplicationTable data={data} /> : ''}
    </>
  );
};

export default AllApplications;
