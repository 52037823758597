import { errorMessageStyle } from 'features/profile/helper';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  FormikErrors,
  useFormikContext,
} from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetUserQuery, useUpdateUserMutation } from '../userSlice';
import Loading from 'layout/Loading';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useGetindustryQuery } from 'components/helper/globalSlice';

import ProfileImage from './ProfileImage';
import { PorfileNavigation } from 'features/profile/types';

const Index = ({
  item,
  handleSwitch,
  profileCompleted,
  setShowFullProfile,
}: {
  item: PorfileNavigation;
  handleSwitch: (value: number) => void;
  profileCompleted: boolean;
  setShowFullProfile: (value: boolean) => void;
}) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const [updateUser] = useUpdateUserMutation();
  const { isLoading, isSuccess, data } = useGetUserQuery();
  const [verified, setVerified] = useState(data?.verified);

  const streams = data?.partnerId?.streams || [];
  const courses = data?.partnerId?.courses || [];
  const { data: industryData = [] } = useGetindustryQuery();
  const initialValues = {
    firstName: data?.firstName,
    lastName: data?.lastName,
    industryId: data?.industryId?._id,
    currentRole: data?.currentRole,
    email: data?.email,
    contactNumber: data?.contactNumber || '',
    country: data?.country,
    linkedInUrl: data?.linkedInUrl,
    about: data?.about,
    stream: data?.stream || '',
    course: data?.course || '',
    graduationYear: data?.graduationYear || 2023,
    universityEnrollmentId: data?.universityEnrollmentId || '',
  };
  type FormValues = {
    firstName: string;
    lastName: string;
  };
  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const {
      errors,
      isSubmitting,
    }: {
      errors: FormikErrors<FormValues>;
      isSubmitting: boolean;
    } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((value: string) => {
          toast.error(value);
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';

  return isLoading ? (
    <Loading />
  ) : isSuccess ? (
    <div>
      <div className='text-sm font-light text-landing flex justify-between'>
        <p>Give your basic information for the Profile</p>
        <p className='bg-neutral-200 px-4 py-2 rounded text-center text-xs md:text-base'>
          Section
          <span className='font-semibold'> 1</span>/7
        </p>
      </div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          firstName: Yup.string().required('First Name is a required field.'),
          lastName: Yup.string().required('Last Name is a required field.'),
          contactNumber: Yup.string().required('Contact Number is required.'),
          linkedInUrl: Yup.string().matches(
            /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+\/?$/i,
            'Please enter a valid LinkedIn profile URL'
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updateUser(values)
            .unwrap()
            .then((res) => {
              setSubmitting(false);
              handleSwitch(item.id + 1);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <>
            <ShowErrorsInToast />
            <Form>
              <div className='lg:flex'>
                <div className='lg:w-1/5'>
                  <ProfileImage />

                  {/* <div className='w-40 h-40 rounded-full bg-red-100 mx-auto'></div> */}
                </div>
                <div className='w-4/5 mt-4'>
                  <div className='grid grid-cols-6 gap-4'>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='firstName'
                        className='block text-sm font-medium text-neutral-900 md:font-bold'
                      >
                        First name
                      </label>
                      <Field
                        id='firstName'
                        type='text'
                        name='firstName'
                        placeholder='First Name'
                        className={inputStyle}
                      />
                      <div className={errorMessageStyle}>
                        <ErrorMessage name='firstName' />
                      </div>
                    </div>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='lastName'
                        className='block text-sm font-medium text-neutral-900 md:font-bold'
                      >
                        Last name
                      </label>
                      <Field
                        id='lastName'
                        type='text'
                        name='lastName'
                        placeholder='Last Name'
                        className={inputStyle}
                      />
                      <div className={errorMessageStyle}>
                        <ErrorMessage name='lastName' />
                      </div>
                    </div>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='title'
                        className='block text-sm font-medium text-neutral-900 md:font-bold'
                      >
                        Title
                      </label>
                      <Field
                        id='currentRole'
                        type='text'
                        name='currentRole'
                        placeholder='Software Developer'
                        className={inputStyle}
                      />
                      <div className={errorMessageStyle}>
                        <ErrorMessage name='currentRole' />
                      </div>
                    </div>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='industry'
                        className='block text-sm font-medium text-neutral-900 md:font-bold'
                      >
                        Industry
                      </label>
                      <Field
                        id='industryId'
                        as='select'
                        name='industryId'
                        placeholder='Industry'
                        className={inputStyle}
                      >
                        {' '}
                        <option value=''>Select Industry</option>
                        {industryData?.map((industry: any) => {
                          return (
                            <option key={industry._id} value={industry._id}>
                              {industry.title}
                            </option>
                          );
                        })}
                      </Field>
                      <div className={errorMessageStyle}>
                        <ErrorMessage name='industryId' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-12 gap-4 mt-4'>
                <div className='col-span-10 sm:col-span-4'>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    Email ID
                  </label>
                  <Field
                    id='email'
                    type='email'
                    name='email'
                    placeholder='Email'
                    className={inputStyle}
                    disabled
                  />
                  <div className={errorMessageStyle}>
                    <ErrorMessage name='email' />
                  </div>
                </div>
                <div className='col-span-8 sm:col-span-4'>
                  <label
                    htmlFor='phone'
                    className='mb-1 block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    Phone Number
                  </label>

                  <PhoneInput
                    value={values.contactNumber}
                    onChange={(phone) => setFieldValue('contactNumber', phone)}
                    preferredCountries={['in', 'za']}
                    country={'in'}
                    inputStyle={{ width: '100%' }}
                    countryCodeEditable={false}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                    }}
                  />
                  <div className={errorMessageStyle}>
                    <ErrorMessage name='contactNumber' />
                  </div>
                </div>
                <div className='col-span-6 sm:col-span-4'>
                  <label
                    htmlFor='location'
                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    Location
                  </label>
                  <Field
                    id='country'
                    type='text'
                    name='country'
                    placeholder='Location'
                    className={inputStyle}
                  />
                  <div className={errorMessageStyle}>
                    <ErrorMessage name='country' />
                  </div>
                </div>
                <div className='col-span-6 sm:col-span-4'>
                  <label
                    htmlFor='graduationYear'
                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    Graduation Year
                  </label>
                  <Field
                    id='graduationYear'
                    as='select'
                    name='graduationYear'
                    className={inputStyle}
                  >
                    <option value={2010}>2010</option>
                    <option value={2011}>2011</option>
                    <option value={2012}>2012</option>
                    <option value={2013}>2013</option>
                    <option value={2014}>2014</option>
                    <option value={2015}>2015</option>
                    <option value={2016}>2016</option>
                    <option value={2017}>2017</option>
                    <option value={2018}>2018</option>
                    <option value={2019}>2019</option>
                    <option value={2020}>2020</option>
                    <option value={2021}>2021</option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                    <option value={2025}>2025</option>
                    <option value={2026}>2026</option>
                    <option value={2027}>2027</option>
                    <option value={2028}>2028</option>
                  </Field>
                  <div className={errorMessageStyle}>
                    <ErrorMessage name='graduationYear' />
                  </div>
                </div>
                <div className='col-span-6 sm:col-span-4'>
                  <label
                    htmlFor='universityEnrollmentId'
                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    University Enrollment Id
                  </label>
                  <Field
                    id='universityEnrollmentId'
                    type='text'
                    name='universityEnrollmentId'
                    placeholder='University Enrollment Id'
                    className={inputStyle}
                  />
                  <div className={errorMessageStyle}>
                    <ErrorMessage name='universityEnrollmentId' />
                  </div>
                </div>
                <div className='col-span-6 sm:col-span-4'>
                  <label
                    htmlFor='stream'
                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    Stream
                  </label>
                  <Field
                    id='stream'
                    as='select'
                    name='stream'
                    className={inputStyle}
                  >
                    <option value=''>Select stream</option>
                    {streams.map((stream: any) => {
                      return (
                        <option key={stream} value={stream}>
                          {stream}
                        </option>
                      );
                    })}
                  </Field>
                  <div className={errorMessageStyle}>
                    <ErrorMessage name='course' />
                  </div>
                </div>
                <div className='col-span-6 sm:col-span-4'>
                  <label
                    htmlFor='course'
                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    Course
                  </label>
                  <Field
                    id='course'
                    as='select'
                    name='course'
                    className={inputStyle}
                  >
                    <option value=''>Select Course</option>
                    {courses.map((course: any) => {
                      return (
                        <option key={course} value={course}>
                          {course}
                        </option>
                      );
                    })}
                  </Field>
                  <div className={errorMessageStyle}>
                    <ErrorMessage name='course' />
                  </div>
                </div>

                <div className='col-span-6 sm:col-span-8'>
                  <label
                    htmlFor='linkedin'
                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                  >
                    LinkedIn URL
                  </label>
                  <Field
                    id='linkedInUrl'
                    type='text'
                    name='linkedInUrl'
                    placeholder='LinkedIn URL'
                    className={inputStyle}
                  />
                  <div className={errorMessageStyle}>
                    <ErrorMessage name='linkedInUrl' />
                  </div>
                </div>
                <div className='col-span-12'>
                  <div>
                    <label
                      htmlFor='about'
                      className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
                    >
                      Description
                    </label>
                  </div>
                  <div>
                    <div className='w-full mt-1'>
                      <Field
                        as='textarea'
                        id='about'
                        name='about'
                        rows={8}
                        className='p-2 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                      />
                      <div className={errorMessageStyle}>
                        <ErrorMessage name='about' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-span-12 text-right'>
                  {profileCompleted && (
                    <span
                      onClick={() => {
                        setShowFullProfile(true);
                      }}
                      className='text-[#797979] text-sm mr-4 cursor-pointer'
                    >
                      VIEW PROFILE
                    </span>
                  )}
                  {verified ? (
                    <button
                      type='button'
                      className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                      onClick={openModal}
                    >
                      UPDATE
                    </button>
                  ) : (
                    <button
                      disabled={isSubmitting}
                      className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                    >
                      {isSubmitting ? 'Updating' : 'SAVE'}
                    </button>
                  )}
                </div>
              </div>
            </Form>
            <Transition appear show={isOpen} as={Fragment}>
              <Dialog as='div' className='relative z-10' onClose={closeModal}>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='fixed inset-0 bg-black bg-opacity-25' />
                </Transition.Child>

                <div className='fixed inset-0 overflow-y-auto'>
                  <div className='flex min-h-full items-center justify-center p-4 text-center'>
                    <Transition.Child
                      as={Fragment}
                      enter='ease-out duration-300'
                      enterFrom='opacity-0 scale-95'
                      enterTo='opacity-100 scale-100'
                      leave='ease-in duration-200'
                      leaveFrom='opacity-100 scale-100'
                      leaveTo='opacity-0 scale-95'
                    >
                      <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                        <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                          <button
                            type='button'
                            className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            onClick={() => setIsOpen(false)}
                          >
                            <span className='sr-only'>Close</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                        <div className='sm:flex sm:items-start'>
                          <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                            <ExclamationTriangleIcon
                              className='h-6 w-6 text-red-600'
                              aria-hidden='true'
                            />
                          </div>
                          <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                            <Dialog.Title
                              as='h3'
                              className='text-base font-semibold leading-6 text-gray-900'
                            >
                              Update Profile?
                            </Dialog.Title>
                            <div className='mt-2'>
                              <p className='text-sm text-gray-500'>
                                Your current profile status is{' '}
                                <span className='text-green-600 font-medium'>
                                  Verified
                                </span>
                                . Are you sure you want to update your profile
                                and get it re-verified by the placement office?
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                          <button
                            type='button'
                            className='inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto'
                            onClick={() => {
                              setVerified(false);
                              closeModal();
                            }}
                          >
                            Proceed
                          </button>
                          <button
                            type='button'
                            className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </>
        )}
      </Formik>
    </div>
  ) : (
    <div></div>
  );
};

export default Index;
