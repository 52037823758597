import classNames from 'classnames';
import { useGetApplicationsQuery } from 'features/home/applicationSlice';
import Error from 'layout/Error';
import Apply from './Apply';
import { format } from 'date-fns';

const Application = ({ roleId }: { roleId: string }) => {
  const { data, isError, isSuccess } = useGetApplicationsQuery({
    limit: 1,
    onlyUser: true,
    placementRoleId: roleId,
  });

  return (
    <>
      {isError && <Error />}
      {isSuccess &&
        (data?.length > 0 ? (
          <>
            <p>
              Your curreent stage is{' '}
              <span className='font-medium'>{data[0].currentStage}</span> and
              your status is{' '}
              <span
                className={classNames(
                  'inline-flex rounded-full  px-2 py-1 text-xs font-semibold leading-5 ',
                  {
                    'bg-green-100 text-green-800':
                      data[0].currentStatus === 'Hired',
                  },
                  {
                    'bg-blue-100 text-blue-800':
                      data[0].currentStatus === 'Applied',
                  },
                  {
                    'bg-yellow-100 text-yellow-800':
                      data[0].currentStatus === 'Shortlisted',
                  },
                  {
                    'bg-red-100 text-red-800':
                      data[0].currentStatus === 'Not Hired',
                  }
                )}
              >
                {data[0].currentStatus}
              </span>
            </p>
            {data[0].updatedAt && (
              <p>
                Last Updated:{' '}
                {format(new Date(data[0].updatedAt), 'do MMMM yyy')}
              </p>
            )}
          </>
        ) : (
          <Apply roleId={roleId} />
        ))}
    </>
  );
};

export default Application;
