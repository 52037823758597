import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import StatusHistory from './StatusHistory';
import { useUpdateApplicationMutation } from 'features/home/applicationSlice';

const StatusPopup = ({
  isOpen,
  setIsOpen,
  notificationStatus,
  applicationId,
  statusHistory,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  notificationStatus: boolean;
  applicationId: string;
  statusHistory: any;
}) => {
  const [update] = useUpdateApplicationMutation();

  useEffect(() => {
    if (notificationStatus) {
      update({ applicationId })
        .unwrap()
        .then((res) => {});
    }
  }, [notificationStatus, applicationId, update]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <div className='mt-4'>
                  <StatusHistory statusHistory={statusHistory} />
                </div>
                <div className='mt-4'>
                  <button
                    type='button'
                    className='ml-6 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50  sm:text-sm'
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StatusPopup;
