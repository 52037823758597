import { useGetUserQuery } from 'components/profile/userSlice';
import Loading from 'layout/Loading';
import Home from 'components/home';
import ApplicationDetails from 'components/home/ApplicationDetails';
import Announcements from 'components/announcements';

const Index = () => {
  const { isLoading, isSuccess, data } = useGetUserQuery();

  return (
    <div className='grid grid-cols-4 gap-8'>
      <div className='col-span-12 lg:col-span-2'>
        {isLoading && <Loading />}
        {isSuccess && <Home data={data} />}
      </div>
      <div className='col-span-12 lg:col-span-10'>
        <Announcements />
      </div>
      <div className='col-span-12'>
        <ApplicationDetails />
      </div>
    </div>
  );
};

export default Index;
