import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3BottomLeftIcon,
  BellIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  BriefcaseIcon,
} from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { useGetUserQuery } from 'components/profile/userSlice';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { Auth } from 'aws-amplify';

const userNavigation = [
  { name: 'Your Profile', href: '/profile' },
  // { name: 'Settings', href: '#' },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const sideBarConfig = [
  {
    path: '/',
    text: 'Home',
    icon: HomeIcon,
    isDisabled: false,
  },
  {
    path: '/profile',
    text: 'Profile',
    icon: UsersIcon,
    isDisabled: false,
  },
  {
    path: '/roles',
    text: 'Roles',
    icon: BriefcaseIcon,
    isDisabled: false,
  },
];

export default function DashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data } = useGetUserQuery();

  const imageUrl = data?.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + data?.imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1660803220/uploaded_resources/am-a-19-year-old-multimedia-artist-student-from-manila--21_bk3itt.png';
  const navigate = useNavigate();
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-40 md:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex flex-shrink-0 items-center px-4'>
                    <img
                      className='h-8 w-auto'
                      src='https://res.cloudinary.com/belong/image/upload/v1650621217/uploaded_resources/logo_gqty7l.png'
                      alt='Your University'
                    />
                  </div>
                  <div className='mt-5 h-0 flex-1 overflow-y-auto'>
                    <nav className='space-y-1 px-2'>
                      {sideBarConfig.map((element) => {
                        return (
                          <div key={element.path}>
                            <NavLink
                              className={({ isActive }) =>
                                isActive
                                  ? 'bg-primary-lightest rounded-full w-full inline-block text-gray-500 px-6 py-4'
                                  : element.isDisabled
                                  ? 'rounded-full w-full inline-block px-6 py-4 pointer-events-none'
                                  : 'hover:bg-primary-lightest transition-all ease-in-out duration-300 text-gray-500 rounded-full w-full inline-block px-6 py-4'
                              }
                              to={element.path}
                            >
                              <div className='flex justify-between'>
                                <div className='flex items-center space-x-4 '>
                                  <element.icon
                                    className='text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                                    aria-hidden='true'
                                  />
                                  <span className='font-semibold text-textColor-main/60'>
                                    {element.text}
                                  </span>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        );
                      })}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col '>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5 md:pr-4'>
            <div className='flex flex-shrink-0 items-center px-4'>
              <img
                className='h-8 w-auto'
                src='https://res.cloudinary.com/belong/image/upload/v1650621217/uploaded_resources/logo_gqty7l.png'
                alt='Your Company'
              />
            </div>
            <div className='mt-5 flex flex-grow flex-col'>
              <nav className='space-y-2 px-2'>
                {sideBarConfig.map((element) => {
                  return (
                    <div key={element.path}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? 'bg-primary-lightest rounded-full w-full inline-block text-gray-500 px-6 py-4'
                            : element.isDisabled
                            ? 'rounded-full w-full inline-block px-6 py-4 pointer-events-none'
                            : 'hover:bg-primary-lightest transition-all ease-in-out duration-300 text-gray-500 rounded-full w-full inline-block px-6 py-4'
                        }
                        to={element.path}
                      >
                        <div className='flex justify-between'>
                          <div className='flex items-center space-x-4 '>
                            <element.icon
                              className='text-gray-500 mr-3 flex-shrink-0 h-6 w-6'
                              aria-hidden='true'
                            />
                            <span className='font-semibold text-textColor-main/60'>
                              {element.text}
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
        <div className='flex flex-1 flex-col md:pl-64'>
          <div className='sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow'>
            <button
              type='button'
              className='border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <Bars3BottomLeftIcon className='h-6 w-6' aria-hidden='true' />
            </button>
            <div className='flex flex-1 justify-between px-4'>
              <div className='flex flex-1'>
                <form className='flex w-full md:ml-0' action='#' method='GET'>
                  <label htmlFor='search-field' className='sr-only'>
                    Search
                  </label>
                  <div className='hidden relative w-full text-gray-400 focus-within:text-gray-600'>
                    <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center'>
                      <MagnifyingGlassIcon
                        className='h-5 w-5'
                        aria-hidden='true'
                      />
                    </div>
                    <input
                      id='search-field'
                      className='block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm'
                      placeholder='Search'
                      type='search'
                      name='search'
                    />
                  </div>
                </form>
              </div>
              <div className='ml-4 flex items-center md:ml-6'>
                <button
                  type='button'
                  className='hidden rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  <span className='sr-only'>View notifications</span>
                  <BellIcon className='h-6 w-6' aria-hidden='true' />
                </button>

                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                      <span className='sr-only'>Open user menu</span>
                      <img
                        className='h-8 w-8 rounded-full'
                        src={imageUrl}
                        alt=''
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        <button
                          className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                          onClick={async () => {
                            await Auth.signOut({ global: true });
                            navigate('/login', { replace: true });
                          }}
                        >
                          Sign out
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className='flex-1 mt-4'>
            <div className='py-6'>
              <div className='mx-auto max-w-7xl px-4 sm:px-6 md:px-8'>
                {/* Replace with your content */}
                {<Outlet />}
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
