const Header = ({ view }: { view: string }) => {
  return (
    <div className='sm:flex sm:items-center mb-8'>
      <div className='sm:flex-auto'>
        <h1 className='text-xl font-semibold text-gray-900'>{view}</h1>
        <p className='mt-2 text-sm text-gray-700'>
          {view === 'Roles'
            ? 'A list of all the roles you are eligible to apply for.'
            : 'A list of all the roles you have applied for.'}
        </p>
      </div>
    </div>
  );
};

export default Header;
