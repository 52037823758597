// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

const CheckLink = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const hostname = window.location.hostname || '';
  //   const firsturl = hostname.split('.')[0];
  //   let code = '';
  //   if (
  //     firsturl === 'localhost' ||
  //     firsturl === 'placement-booster-student' ||
  //     firsturl === 'booster'
  //   ) {
  //     code = 'belong';
  //   } else {
  //     code = firsturl;
  //   }
  //   if (code) {
  //     navigate(`/signup/${code}`, { replace: true });
  //   }
  // });

  return (
    <div className='h-screen grid place-items-center'>
      <p>Incorrect URL. Please check the link and try again.</p>
    </div>
  );
};

export default CheckLink;
