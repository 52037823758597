import Loading from 'layout/Loading';
import { format } from 'date-fns';
import { useGetAnnouncementsQuery } from './AnnouncementSlice';
import AnnouncementPopup from './AnnouncementPopup';
import { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
const AllAnnouncements = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [announcementToShow, setAnnouncementToShow] = useState(null);

  const { data, isLoading, isSuccess } = useGetAnnouncementsQuery({
    limit: 5,
  });

  return (
    <div>
      {isLoading && <Loading />}
      {isSuccess && data.length > 0 ? (
        <div className='mt-8 flex flex-col'>
          <h3 className='mt-2 mb-4 text-lg font-semibold tracking-tight text-gray-900'>
            Announcements
          </h3>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Title
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                      >
                        Date
                      </th>
                      <th
                        scope='col'
                        className='relative py-3.5 pl-3 pr-4 sm:pr-6'
                      >
                        <span className='sr-only'>View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {data?.map((announcement: any) => (
                      <tr key={announcement._id}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <p className='flex items-center font-medium text-gray-900'>
                            {announcement.title}
                          </p>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {format(
                            new Date(announcement.createdAt),
                            'do LLLL yyyy'
                          )}
                        </td>
                        <td className='relative whitespace-nowrap  pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                          <button
                            onClick={() => {
                              setAnnouncementToShow(announcement);
                              setIsOpen(true);
                            }}
                            className='text-indigo-600 hover:text-indigo-900 flex'
                          >
                            View
                          </button>
                          {announcement.actionRequired && (
                            <InformationCircleIcon className='h-5 w-5 text-yellow-500' />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <AnnouncementPopup
            data={announcementToShow}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      ) : (
        <div>{/* if 0 Announcements */}</div>
      )}
    </div>
  );
};

export default AllAnnouncements;
