import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/placement-role',
          method: 'GET',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Roles'],
    }),
    getCompany: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/placement-company',
          method: 'GET',
          params: { ...params, limit: 1 },
        };
      },
      transformResponse: (res: any) => res.data,
    }),
    applyApplication: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/placement-application/apply`,
          method: 'POST',
          body,
        };
      },
      // Invalidates all queries that subscribe to the `Partner`.
      invalidatesTags: ['Applications', 'Roles'],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetCompanyQuery,
  useApplyApplicationMutation,
} = extendedApiSlice;
