import { useGetRolesQuery } from 'features/roles/rolesSlice';
import Error from 'layout/Error';
import Loading from 'layout/Loading';
import { useParams } from 'react-router-dom';
import RoleDetail from './RoleDetail';

const ViewRole = () => {
  const { roleId } = useParams();

  const { data, isLoading, isError, isSuccess } = useGetRolesQuery({
    placementRoleId: roleId,
  });

  return (
    <>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess && data.length > 0 ? (
        <RoleDetail data={data} />
      ) : (
        'Please check the URL'
      )}
    </>
  );
};

export default ViewRole;
